<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!--상세실적-->
      <c-table
        ref="table"
        title="관계법령 의무사항 이행 실적"
        :merge="grid.merge"
        :columns="grid.columns"
        :data="grid.data"
        :gridHeight="grid.height"
        :editable="editable"
        :usePaging="false"
        :isExcelDown="true"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="mainSafetyHealthyTargetResultId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLREMOVE" icon="delete" @btnClicked="deleteResult" />
            <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addResult" />
            <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveResult" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-healthy-target-detail-popup',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mainSafetyHealthyTargetId: '',
        mainSafetyHealthyTargetPlanId: '',
      }),
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '600px',
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.mainsafetyHealthy.result.list.url;
      this.saveUrl = transactionConfig.sai.mainsafetyHealthy.result.save.url;
      this.deleteUrl = transactionConfig.sai.mainsafetyHealthy.result.delete.url;
      // code setting
      // list setting
      let months = [];
      for (let i = 1; i < 13; i++) {
        months.push({
          code: String(i),
          codeName: String(i) + this.$comm.getLangLabel('LBL0010043'), //월
        })
      }
      this.grid.columns = [
        {
          required: true,
          name: 'month',
          field: 'month',
          //실적 월
          label: 'LBL0010044',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          type: 'select',
          comboItems: months,
        },
        {
          required: true,
          name: 'resultContents',
          field: 'resultContents',
          //실적내용
          label: 'LBL0010039',
          align: 'left',
          type: 'textarea',
          sortable: false,
        },
        {
          name: 'managerUserName',
          field: 'managerUserName',
          label: 'LBLMANAGER',
          align: 'center',
          type: 'user',
          userId: 'managerUserId',
          style: 'width: 120px',
          sortable: false,
        },
      ]
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mainSafetyHealthyTargetPlanId: this.popupParam.mainSafetyHealthyTargetPlanId
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.$set(this.returnData, 'col1', this.$_.reject(this.grid.data, {editFlag: 'C'}))
      },);
    },
    addResult() {
      this.grid.data.splice(0, 0, {
        mainSafetyHealthyTargetResultId: uid(),  // 안전보건목표 실적 일련번호
        mainSafetyHealthyTargetPlanId: this.popupParam.mainSafetyHealthyTargetPlanId,  // 안전보건목표 계획 일련번호
        mainSafetyHealthyTargetId: this.popupParam.mainSafetyHealthyTargetId,  // 안전보건목표 일련번호
        month: null,  // 실적월
        resultContents: '',  // 실적내용
        managerUserId: this.$store.getters.user.userId,  // 담당자id
        managerUserName: this.$store.getters.user.userName,  // 담당자id
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    saveResult() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.grid.data
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteResult() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              this.$set(this.returnData, 'col1', this.grid.data)
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>